import './App.css';
import luhn from 'luhn-generator/dist/luhn';

function App() {

  const generatedNumber = () => {
    let kid = luhn.random(10);
    document.getElementById("span_num").innerHTML = kid;
  };

  return (
    <div className="App">
      <div className='hero'>
        <div className='container'>
          <div>
            <h1>No <span id="kid_text">KID</span>ding</h1>
            <span id="info">Used to generate valid KID numbers.</span>
          </div>
            <div id="number">
                <span id="span_num">1337</span>
            </div>
          <button onClick={generatedNumber}>Generate</button>
        </div>
  
        </div>
    </div>
  );
}

export default App;
